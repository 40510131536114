/* Login.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("https://www.britishtriathlon.org/images/backgrounds/btf-login-background.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .login-container {
    background-color: white;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .btf-logo {
    max-width: 150px;
    margin-bottom: 1rem;
  }
  
  .login-input {
    width: 100%;
    padding: 0.8rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 1rem;
  }
  
  .login-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #0056b3;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    margin-top: 1rem;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #004494;
  }
  
  .login-links,
  .additional-links {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .login-links a,
  .additional-links a {
    color: #0056b3;
    text-decoration: none;
  }
  
  .login-links a:hover,
  .additional-links a:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  