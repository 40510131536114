/* Header container styling */
.header {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent; /* Remove any background color */
}
  
/* Header content styling with white background */
.header-content {
    display: flex;
    align-items: center;
    background-color: #fff; /* White background */
    padding: 10px 20px;
    position: relative;
    box-shadow: none; /* Remove any shadow or border-like effects */
}
  
/* Logo styling without background */
.header-logo {
    width: 150px;
    position: absolute;
    top: -10px; /* Adjust to overlap the header */
    left: 20px; /* Space from the left side */
    padding: 10px;
    box-shadow: none; /* Ensure no shadow around logo */
}
  
/* Navigation styling */
.header-nav {
    margin-left: auto; /* Push nav to the right */
    display: flex;
    gap: 15px;
    border: none; /* Ensure no border */
}
  
.header-nav a {
    color: #141413; /* Dark gray color */
    font-family: "Inter", Arial, sans-serif; /* Font family */
    font-size: 16px; /* Font size */
    text-decoration: none;
    font-weight: normal; /* Normal font weight */
    margin: 0px 0px -8px 0px; /* Apply specified margin */
    padding: 0px 12px; /* Apply specified padding */
    border: none; /* Ensure no border */
}
  
.header-nav a:hover {
    text-decoration: underline;
}
  
/* Membership button styling */
.membership-button {
    background-color: #FF66A5; /* Pink color */
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any shadow */
}
  
.membership-button:hover {
    background-color: #ff3381;
}
  
/* My Account title styling with background image and right alignment */
.my-account-title {
    background-image: url('https://www.britishtriathlon.org/britain/images/section-head-images/ag/ag-old.png');
    background-size: cover;
    background-position: center;
    padding: 20px;
    text-align: right; /* Align the title to the right */
    border: none; /* Ensure no border */
}
  
.my-account-title h1 {
    font-size: 2em;
    color: #fff;
    margin: 0;
    font-weight: bold;
    border: none; /* Ensure no border */
}
