.footer {
    background-color: #002855;
    color: white;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: Arial, sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    text-align: left;
}

/* Footer sections styling */
.footer-section {
    flex: 1;
    margin: 0 10px;
}

.footer-section h4 {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-section p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #dcdcdc;
    margin: 5px 0;
}

.footer-section a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.footer-section a:hover {
    text-decoration: underline;
}

.footer-section .footer-links {
    margin-top: 10px;
}

/* Contact Button Styling */
.footer .contact-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #ffffff;
    color: #002855;
    text-decoration: none;
    font-size: 0.9rem;
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
}

.footer .contact-button:hover {
    background-color: #f2f2f2;
    color: #001f3f;
}

/* Footer logo styling */
.footer-logo {
    width: 100px; /* Adjust width as needed */
    margin-bottom: 1rem;
}
