/* Header container styling */
.header {
  background-color: #1E2A44; /* Dark blue background */
  color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

/* Header content styling */
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo styling */
.header-logo {
  width: 150px;
}

/* Navigation styling */
.header-nav {
  display: flex;
  gap: 15px;
}

.header-nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.header-nav a:hover {
  text-decoration: underline;
}

/* Membership button styling */
.membership-button {
  background-color: #FF66A5; /* Pink color */
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
}

.membership-button:hover {
  background-color: #ff3381;
}

/* My Account title styling */
.my-account-title {
  background-color: #1E2A44; /* Dark blue background */
  padding: 20px;
  text-align: center;
}

.my-account-title h1 {
  font-size: 2em;
  color: #fff;
  margin: 0;
}
